/* eslint-disable react-hooks/exhaustive-deps */
import { Slide, ToastContainer } from "react-toastify";
import { Route, Routes, } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Customers from "./Pages/Customer/Customers";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Error404 from "./Components/Others/Error404";
import Settings from "./Pages/Settings/Settings";
import Shops from "./Pages/Shop/Shops";
import Canondale from "./Pages/Test/Canondale";
import Test from "./Pages/Test/Test";
import GiftCards from "./Pages/GiftCards/GiftCards";
import Order from "./Pages/Order/Order";
import ShopDetails from "./Pages/Shop/Details/ShopDetails";
import ShopTable from "./Pages/Shop/DataTable/ShopTable";
import FaqDetails from "./Pages/Settings/Details/FaqDetails";
import LanguageDetails from "./Pages/Settings/Details/LanguageDetails";
import PrivacyDetails from "./Pages/Settings/Details/PrivacyDetails";
import TermsDetails from "./Pages/Settings/Details/TermsDetails";
import ChangePassword from "./Pages/Settings/Details/ChangePassword";
import VoucherDetails from "./Pages/Settings/Details/VoucherDetails";

import GiftCardDetails from "./Pages/GiftCards/GiftCardDetails";
import CustomerTable from "./Pages/Customer/DataTable/CustomerTable";
import CustomerDetails from "./Pages/Customer/Details/CustomerDetails";
import ContactMessage from "./Pages/ContactMessage/ContactMessage";
import Category from "./Pages/Category/Category";
import CategoryTable from "./Pages/Category/DataTable/CategoryTable";
import RecommendedCategories from "./Pages/Settings/Details/RecommendedCategories";
import BoostedShop from "./Pages/Settings/Details/BoostedShop/BoostedShop";
import NoBoostedShops from "./Pages/Settings/Details/BoostedShop/NoBoostedShops";
import AddBoostShop from "./Pages/Settings/Details/BoostedShop/AddBoostShop";
import RoleManagement from "./Pages/RoleManagement/RoleManagement";
import NoRole from "./Pages/RoleManagement/NoRole";
import AdminTable from "./Pages/RoleManagement/Admin/AdminTable";
import NoAdmin from "./Pages/RoleManagement/Admin/NoAdmin";

import AllFees from "./Pages/Settings/Details/AllFees";
import Login from "./Pages/Auth/Login";
import ShowRecentOrder from "./Pages/Dashboard/DataTable/ShowRecentOrder";
import AxiosHeader from "./App/Utility/AxiosHeader";
import Logs from "./Pages/Logs/Logs";
import AccountSettings from "./Pages/AccountSettings/AccountSettings";
import LoadingModal from "./Components/Modal/LoadingModal";
import LoginScreen from "./Pages/Auth/LoginScreen";
import VoucherTable from "./Pages/Voucher/VoucherTable";
import AddVoucher from "./Pages/Voucher/AddVoucher";
import NoVoucher from "./Pages/Voucher/NoVoucher";
import OverlayModalArea from "./Components/Layout/OverlayModalArea";
import useGiftCardStore from "./App/Stores/GiftCardStore";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useAuthStore from "./App/Stores/authStore";
import { changeLanguage } from "./App/Stores/SettingsStore";
import i18next from "i18next";
import SplashScreen from "./Components/Others/SplashScreen";
import AboutUs from "./Pages/Settings/Details/AboutUs";
import VerifyIdentity from "./Pages/VerifyIdentity/VerifyIdentity";
import CurrentRequest from "./Pages/VerifyIdentity/IdentityVerifyDetails/CurrentRequest";
import OrderHistoryTableItem from "./Pages/Customer/DataTable/OrderHistoryTableItem";
import CustomerVerificationHistory from "./Pages/Customer/DataTable/CustomerVerificationHistory";
import IdentityVerificationHistory from "./Pages/VerifyIdentity/IdentityVerifyDetails/VerificationHistory";
import VerifyIdentityParent from "./Pages/VerifyIdentity/VerifyIdentityParent";
import NotificationManagement from "./Pages/Notification/NotificationManagement";
import DeleteMessage from "./Pages/Settings/Details/DeleteMessage";


if (localStorage.postcard_token) {
  AxiosHeader({ token: localStorage.postcard_token });
} else {
  AxiosHeader({ token: null });
}

function App() {
  const { t } = useTranslation();

  const { giftCardFilterCategory } = useGiftCardStore();
  const { isLoggedIn } = useAuthStore();
  const [is_init, setInit] = useState(false);


  const headers = [
    { index: 1, name: t("Shop name") },
    { index: 2, name: t("address") },
    { index: 3, name: t("total cards") },
    { index: 4, name: t("total orders") },
    { index: 5, name: t("pending orders") },
    { index: 6, name: t("Action") },
  ];

  const roleHeaders = [
    { index: 1, name: t("name") },
    { index: 2, name: t("access to") },
    { index: 3, name: t("action") },
  ];

  const customerHeaders = [
    { index: 1, name: t("Customer Name") },
    { index: 2, name: t("Address") },
    { index: 3, name: t("Phone No") },
    { index: 4, name: t("Total Orders") },
    { index: 5, name: t("Recent Order") },
    { index: 6, name: t("Action") },
  ];

  const categoryHeaders = [
    { index: 1, name: t("Category Name") },
    { index: 2, name: t("Total Card") },
    { index: 3, name: t("Total Order") },
    { index: 4, name: t("Pending Order") },
    { index: 5, name: t("Action") },
  ];

  // initially set the language to JP
  useEffect(() => {
    // check if logged in
    if (isLoggedIn) {
      // only then use from local storage
      let currentLang = localStorage.getItem("lang_code");
      if (currentLang === "en" || currentLang === 'en-GB' || currentLang === 'en-US') {
        changeLanguage("en");
        i18next.changeLanguage("en");
      }
      else {
        changeLanguage("ja");
        i18next.changeLanguage("ja");
      }
    } else {
      i18next.changeLanguage("ja");
    }
    setTimeout(() => setInit(true), 3000);
  }, [])

  return (
    <div className="bg-cLayoutBg text-cTextBlack">
      {!is_init ? <SplashScreen /> :
        <>
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Slide}
            theme="dark"
            limit={2}
          />

          <LoadingModal />
          <OverlayModalArea />

          <Routes>
            {/* red 404 not found */}
            <Route exact path="/*">
              <Route
                path="*"
                element={
                  <Layout>
                    <Error404 />
                  </Layout>
                }
              />
            </Route>

            {/* orange: login */}
            <Route
              exact
              path="/login"
              element={
                <>
                  <Login />
                </>
              }
            ></Route>

            {/* blue: canondale */}
            <Route
              exact
              path="/home"
              element={
                <Layout>
                  <Canondale />
                </Layout>
              }
            ></Route>

            {/* blue: dashboard */}
            <Route
              exact
              path="/"
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            ></Route>

            {/* blue: recent orders */}
            <Route
              exact
              path="/recent-orders"
              element={
                <Layout>
                  <ShowRecentOrder />
                </Layout>
              }
            ></Route>

            {/* blue: customer */}
            <Route
              exact
              path="/customers"
              element={
                <Layout>
                  <Customers />
                </Layout>
              }
            >
              <Route
                index
                element={
                  <CustomerTable
                    title={t("Customer List")}
                    headers={customerHeaders}
                    tableBtnTitle={t("Add Customer")}
                  />
                }
              />
              <Route path="customer-details" element={<OrderHistoryTableItem />} />
              <Route path="verification-history" element={<CustomerVerificationHistory />} />
            </Route>

            {/* blue: shop */}
            <Route
              exact
              path="/shops"
              element={
                <Layout>
                  <Shops />
                </Layout>
              }
            >
              <Route
                index
                element={
                  <ShopTable
                    title={t("Shop List")}
                    headers={headers}
                    tableBtnTitle={t("Add Shop")}
                  />
                }
              />
              <Route path="details" element={<ShopDetails />} />
            </Route>

            {/* blue: category */}
            <Route
              exact
              path="/category"
              element={
                <Layout>
                  <Category />
                </Layout>
              }
            >
              <Route
                index
                element={
                  <CategoryTable
                    title={"Category List"}
                    headers={categoryHeaders}
                    tableBtnTitle={t("Add Category")}
                  />
                }
              />
              <Route path="gift-cards" element={<GiftCards />} />
            </Route>

            {/* blue: order */}
            <Route
              exact
              path="/orders"
              element={
                <Layout>
                  <Order />
                </Layout>
              }
            ></Route>


            {/* blue: Verify Identity Request */}
            <Route
              exact
              path="/verify/identity"
              element={
                <Layout>
                  <VerifyIdentityParent />
                </Layout>
              }
            >
              <Route index element={<VerifyIdentity />} />
              <Route path="details" element={<CurrentRequest />} />
              <Route path="details/history" element={<IdentityVerificationHistory />} />
            </Route>



            <Route
              exact
              path="/gift-cards"
              element={
                <Layout>
                  <GiftCards showPagination={true} />
                </Layout>
              }
            ></Route>


            {/* blue: gift card */}
            {giftCardFilterCategory ?
              <Route Route
                exact
                path={`/gift-cards/filter/${giftCardFilterCategory}`}
                element={
                  <Layout>
                    <GiftCards showPagination={true} />
                  </Layout>
                }
              ></Route>
              :
              <Route Route
                exact
                path={`/gift-cards/filter`}
                element={
                  <Layout>
                    <GiftCards showPagination={true} />
                  </Layout>
                }
              ></Route>
            }

            {/* blue: gift card details */}
            <Route
              exact
              path="/gift-card-details"
              element={
                <Layout>
                  <GiftCardDetails />
                </Layout>
              }
            ></Route>

            {/* blue: Voucher management */}
            <Route exact path="/voucher">
              <Route
                index
                element={
                  <Layout>
                    <VoucherTable />
                  </Layout>
                }
              />

              <Route
                path="add-voucher"
                element={
                  <Layout>
                    <AddVoucher />
                  </Layout>
                }
              />

              <Route
                path="no-voucher"
                element={
                  <Layout>
                    <NoVoucher />
                  </Layout>
                }
              />
            </Route>

            {/* blue: role management */}
            <Route exact path="/role/manage">
              <Route
                index
                element={
                  <Layout>
                    <RoleManagement roleHeaders={roleHeaders} />
                  </Layout>
                }
              />

              <Route
                path="no-role-available"
                element={
                  <Layout>
                    <NoRole />
                  </Layout>
                }
              />

              <Route
                path="admins/no-admin"
                element={
                  <Layout>
                    <NoAdmin />
                  </Layout>
                }
              />

              <Route
                path="admins"
                element={
                  <Layout>
                    <AdminTable />
                  </Layout>
                }
              />
            </Route>

            {/* green: boosted shop */}
            <Route exact path="/boosted-shop">
              <Route
                index
                element={
                  <Layout>
                    <BoostedShop />
                  </Layout>
                }
              />
              <Route
                path="no-shop"
                element={
                  <Layout>
                    <NoBoostedShops />
                  </Layout>
                }
              />
              <Route
                path="add-shop"
                element={
                  <Layout>
                    <AddBoostShop />
                  </Layout>
                }
              />
              <Route
                path="/boosted-shop/details"
                element={
                  <Layout>
                    <ShopDetails />
                  </Layout>
                }
              />
            </Route>

            {/* blue: settings */}
            <Route
              path="/logs"
              element={
                <Layout>
                  <Logs />
                </Layout>
              }
            ></Route>

            {/* blue: settings */}
            <Route
              exact
              path="/settings"
              element={
                <Layout>
                  <Settings />
                </Layout>
              }
            >
              <Route path="faq" element={<FaqDetails />} />
              <Route path="language-setup" element={<LanguageDetails />} />
              <Route path="privacy-policy" element={<PrivacyDetails />} />
              <Route path="terms-condition" element={<TermsDetails />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="account-settings" element={<AccountSettings />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="fees" element={<AllFees />} />
              <Route path="delete-message" element={<DeleteMessage />} />
              {/* <Route path="authority-fee" element={<AuthorityFee />} />
          <Route path="shipping-fee" element={<ShippingFee />} /> */}
              <Route path="voucher" element={<VoucherDetails />} />

              <Route
                path="recommended-categories"
                element={<RecommendedCategories />}
              />
            </Route>

            {/* blue: contact message */}
            <Route
              exact
              path="/contact-message"
              element={
                <Layout>
                  <ContactMessage />
                </Layout>
              }
            ></Route>

            {/* blue: notifications */}
            <Route
              exact
              path="/notifications"
              element={
                <Layout>
                  <NotificationManagement />
                </Layout>
              }
            ></Route>

            {/* blue: testing */}
            <Route
              exact
              path="/test"
              element={
                <Layout>
                  <Test />
                </Layout>
              }
            ></Route>

            {/* blue: testing */}
            <Route exact path="/login2" element={<LoginScreen />}></Route>
          </Routes>
        </>
      }
    </div >
  );
}

export default App;
