import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { getCardOrderList } from "../../App/Stores/GiftCardStore";
import useOrderStore, { updateOrderAction } from "../../App/Stores/OrderStore";
import { formatDate, Toastr } from "../../App/Utility/UtilityFunctions";
import GroupAccordion from "../../Components/Accordion/GroupAccordion";
import CommonButton from "../../Components/Button/CommonButton";
import DropDownBtn from "../../Components/Button/DropDownBtn";
import CommonDropDown from "../../Components/DropDown/CommonDropDown";
import Image from "../../Components/Image/Image";
import CommonModal from "../../Components/Modal/CommonModal";
import { CopyToClipboard, DownloadIcon, NoImageLogo, PrintIcon } from "../../Components/Utility/ImageImports";
import InvoiceReceipt from "./InvoiceReceipt";
import { getAllOrderListOfCustomer } from "../../App/Stores/CustomerStore";
import CommonInput from "../../Components/Input/CommonInput";

// payment_due ? don't show anything
// pending => (processing, cancelled, rejected)
// process => (completed, cancelled, rejected)
// cancelled => No action
// rejected => No action
// completed => No action

const OrderDetails = () => {
  const { t } = useTranslation();

  const {
    showOrderDetails,
    setShowOrderDetails,
    orderDetails,
    orderListPagination,
    setOrderStatusTable,
    setShowOrderDownloadUI,
    customerOrderHistoryPaginationUrl,
    setDropdownItem,
    openedAccordion,
    setOpenedAccordion,
  } = useOrderStore();
  const [orderStatus, setOrderStatus] = useState("");
  const [confirmationNote, setConfirmationNote] = useState("");

  let pendingArray = [
    "processing",
    "canceled",
    "rejected",
  ];

  let processingArray = [
    "completed",
    "canceled",
    "rejected",
  ];

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    pageStyle: `@media print {
      @page {
        size: size: A4 portrait;
        margin: 0;
      }
    }`,
  });

  useEffect(() => {
    if (orderDetails?.status) {
      setOrderStatus(orderDetails?.status)
    }
  }, [orderDetails, orderListPagination]);

  return (
    <>
      <CommonModal
        showModal={showOrderDetails}
        setShowModal={setShowOrderDetails}
        modalTitle={
          <div className="flex justify-between items-center w-full">
            <div className="flex space-x-s10">
              <img
                className="cursor-pointer"
                onClick={() => {
                  handlePrint();
                }}
                src={PrintIcon}
                alt=""
              />
              <img className="cursor-pointer" onClick={() => { setShowOrderDownloadUI(true) }} src={DownloadIcon} alt="" />
            </div>
            <div>{orderDetails?.code}  {t("Details")}</div>
            <div></div>
          </div>

        }
        // widthClass="w-[80vw] sm:w-[70vw] md:w-[60vw] lg:w-[55vw] xl:w-[50vw] 2xl:w-[45vw]"
        mainContent={
          <div
            // ref={componentRef}
            onClick={() => console.log("orderDetails:::", orderDetails)}
            className="pt-5">

            {/* r       print component */}
            <div className="hidden">
              <InvoiceReceipt
                refToPrint={componentRef}

                order_details={orderDetails}

                invoice_id={orderDetails?.code ?? "NA"}
                order_id={orderDetails?.code ?? "NA"}
                purchase_id={orderDetails?.code ?? "NA"}

                date={orderDetails?.order_date ? formatDate(orderDetails?.order_date) : "NA"}

                shop_image={orderDetails?.shop_image_base64 ?? "NA"}
                shop_name={orderDetails?.post_card_with_shop?.shop?.name ?? "NA"}
                phone={orderDetails?.post_card_with_shop?.shop?.phone ?? "NA"}
                address={orderDetails?.post_card_with_shop?.shop?.address ?? "NA"}

                from_name={orderDetails?.customer?.name ?? "NA"}
                from_email={orderDetails?.customer?.email ?? "NA"}
                from_address={orderDetails?.customer?.address ?? "NA"}
                from_phone={orderDetails?.customer?.phone ?? "NA"}

                to_name={orderDetails?.ship_name ?? "NA"}
                to_email={orderDetails?.ship_address_type ?? "NA"}
                to_address={orderDetails?.ship_address ?? "NA"}
                to_phone={orderDetails?.ship_phone ?? "NA"}


                order_item_name={orderDetails?.post_card_with_shop?.name ?? "NA"}
                order_shop={orderDetails?.post_card_with_shop?.shop?.name ?? "NA"}
                order_qty={orderDetails?.quantity ?? "0"}
                order_price={orderDetails?.price ?? "0"}
                order_price_total={
                  orderDetails?.quantity * orderDetails?.price > 0 ? orderDetails?.quantity * orderDetails?.price : "0"
                }

                order_price_auth_fee={orderDetails?.authority_fee ?? "0"}
                order_price_discount={orderDetails?.discount ?? "0"}
                order_price_shipping={orderDetails?.shipping_fee ?? "0"}
                order_grand_total={orderDetails?.order_total ?? "0"}
              />
            </div>

            <GroupAccordion
              isInitOpen={true}
              header="Image"
              body={
                <Image
                  src={orderDetails?.text_image}
                  className="h-[350px] my-s10 rounded-br10 mx-auto"
                  dummyImage={NoImageLogo}
                />
              }
            />

            <div className="pt-5"></div>

            <GroupAccordion
              accordion_type="dependent"
              customOnClick={() => setOpenedAccordion(12)}
              isInitOpen={openedAccordion === 12}
              header="Additional Texts"
              body={
                <div className="relative">
                  {
                    orderDetails?.message ? <div className="mr-s25 py-s10 min-h-[100px]">{orderDetails?.message}</div>
                      : <div className="font-semibold text-cInputBorder py-s10">No data available</div>
                  }
                  {orderDetails?.message ?
                    <div
                      onClick={async () => {
                        await navigator.clipboard.writeText(orderDetails?.message);
                        Toastr({ message: "Text copied to clipboard !", type: "success" });
                      }}
                      className="absolute bg-white rounded-full shadow-lg cursor-pointer -right-s10 top-s10 p-s10"
                    >
                      <img src={CopyToClipboard} alt="" />
                    </div> : ""}
                </div>
              }
            />

            <div className="pt-5"></div>


            <DeliveryDetails
              orderDetails={orderDetails}
              setOrderStatus={setOrderStatus}
              orderStatus={orderStatus}
              processingArray={processingArray}
              pendingArray={pendingArray}
            />

            <ShopDetails
              orderDetails={orderDetails}
            />

            <SummeryOrder
              orderDetails={orderDetails}
            />

            {orderDetails?.status === "cancellation_request" || orderDetails?.status === 'canceled' ? <CancellationRequest
              orderDetails={orderDetails}
              orderStatus={orderStatus}
              setShowOrderDetails={setShowOrderDetails}
              setOrderStatusTable={setOrderStatusTable}
              orderListPagination={orderListPagination}
            /> : ""}

            {orderDetails?.status === 'canceled' ? <CancelAdminConfirmation
              orderDetails={orderDetails}

            /> : ""}

            {orderDetails?.status === "pending" || orderDetails?.status === "processing" ?
              <form
                onSubmit={(e) => e.preventDefault()}
                className={`flex ${orderStatus === "rejected" || orderStatus === "canceled" ? "flex-col space-y-5" : "flex-row"} justify-center pt-5 mt-s5`}
              >
                {
                  orderStatus === "rejected" || orderStatus === "canceled" ?
                    <CommonInput
                      required
                      textarea
                      placeholder={'Please write a note here..'}
                      label={"Confirmation Note"}

                      value={confirmationNote}
                      onChange={(e) => setConfirmationNote(e.target.value)}
                    />
                    : ""
                }
                <div className="flex justify-center">
                  <CommonButton
                    type="submit"
                    btnLabel={t("Confirm")}
                    onClick={async () => {
                      // only for: "/customers/customer-details"
                      if (window.location.pathname === "/customers/customer-details") {

                        let updateSuccess = await updateOrderAction(orderDetails?.id, orderStatus, customerOrderHistoryPaginationUrl, false);
                        // console.log("SUCCESS !!!", updateSuccess);
                        getAllOrderListOfCustomer({}, customerOrderHistoryPaginationUrl, localStorage.getItem("customerID")); //r need customer id 
                        if (updateSuccess === true) {
                          setDropdownItem("all orders");
                          setShowOrderDetails(false)
                          setOrderStatusTable(orderStatus);
                          if (window.location.pathname === "/gift-card-details") {
                            getCardOrderList("all orders", "", "");
                          }
                        }
                        return console.log("TRACKED!");
                      }

                      let updateSuccess;

                      // for reject and cancel need to provide note
                      if (orderStatus === "rejected" || orderStatus === "canceled") {
                        if (!confirmationNote) return
                        else {
                          console.log("READY");
                          updateSuccess = await updateOrderAction(orderDetails?.id, orderStatus, orderListPagination.path + "?page=" + orderListPagination.current_page, true, confirmationNote);
                        }
                      } else
                        updateSuccess = await updateOrderAction(orderDetails?.id, orderStatus, orderListPagination.path + "?page=" + orderListPagination.current_page);
                      // console.log("SUCCESS !!!", updateSuccess);
                      if (updateSuccess === true) {
                        setDropdownItem("all orders");
                        setConfirmationNote('');
                        setShowOrderDetails(false);
                        setOrderStatusTable(orderStatus);
                        if (window.location.pathname === "/gift-card-details") {
                          getCardOrderList("all orders", "", "");
                        }
                      }
                    }}
                  />
                </div>
              </form> : ""
            }
          </div>
        }
      />
    </>
  );
};

export default OrderDetails;

const DeliveryDetails = ({ orderDetails, setOrderStatus, orderStatus, pendingArray, processingArray }) => {
  const { t } = useTranslation();
  const { openedAccordion, setOpenedAccordion } = useOrderStore();

  return (
    <div >
      <GroupAccordion
        accordion_type="dependent"
        customOnClick={() => setOpenedAccordion(1)}
        isInitOpen={openedAccordion === 1}
        header={
          <div className="flex justify-between items-center">
            <div className="font-fw600">{t("Customer Details")}</div>
          </div>
        }
        body={
          <>
            {dataRow(t("Customer Name"), orderDetails?.customer?.name ?? 'NA')}
            {dataRow(t("Phone"), orderDetails?.customer?.phone ?? "NA")}
            {dataRow(t("Address"), orderDetails?.customer?.address ?? "NA")}
            {dataRow(t("Post Code"), orderDetails?.customer?.post_code ?? "NA")}
            {dataRow(t("City"), orderDetails?.customer?.city ?? "NA")}
            <div className="pb-s10"></div>
          </>
        }
        footer={
          <>
            <div className="px-5">

              {orderDetails?.status === "pending" || orderDetails?.status === "processing" ?
                <>
                  {dataRow(t("Delivery Status"), <CommonDropDown
                    toggleButton={
                      <div className="z-50">
                        <DropDownBtn
                          fullRounded={true}
                          basicColor={false}
                          successColor={orderStatus === "completed" ? true : false}
                          dangerColor={orderStatus === "canceled" || orderStatus === "rejected" ? true : false}
                          pendingColor={orderStatus === "pending" || orderStatus === "payment_due" || orderStatus === "processing" ? true : false}
                          btnLabel={orderStatus ? orderStatus === "payment_due" ? "payment due" : orderStatus : orderDetails?.status === "payment_due" ? "payment due" : orderDetails?.status}
                        />
                      </div>
                    }
                    data={orderDetails?.status === "pending" ? pendingArray : orderDetails?.status === "processing" ? processingArray : []}
                    type={2}
                    itemOnClick={(e) => {
                      if (e === "payment due") {
                        setOrderStatus("payment_due");
                      } else {
                        setOrderStatus(e);
                      }
                    }}
                  />)}
                </>
                :
                <>
                  {dataRow(t("Delivery Status"), orderDetails?.status === "payment_due" ? "payment due" : orderDetails?.status === "cancellation_request" ? "cancellation request" : orderDetails?.status)}
                </>
              }

            </div>
            <div className="pb-s10"></div>
          </>
        }
      />


      <div className="mt-s22">
        <GroupAccordion
          accordion_type="dependent"
          customOnClick={() => setOpenedAccordion(2)}
          isInitOpen={openedAccordion === 2}
          header={
            <div className="flex justify-between items-center">
              <div className="font-fw600">{t("Receiver Details")}</div>
            </div>
          }
          body={
            <div className="relative">
              <div
                onClick={async () => {
                  await navigator.clipboard.writeText(orderDetails?.ship_name + "\n" + orderDetails?.ship_phone + "\n" + orderDetails?.ship_postcode + "\n" + orderDetails?.ship_address + "\n" + orderDetails?.ship_city);
                  Toastr({ message: "Receiver address copied to clipboard !", type: "success" });
                }}
                className="absolute bg-white rounded-full shadow-lg cursor-pointer -right-4 top-s10 p-s10"
              >
                <img src={CopyToClipboard} alt="" />
              </div>
              <div className="pr-8">
                {dataRow(t("Receiver Name"), orderDetails?.ship_name ?? 'NA')}
                {dataRow(t("Phone"), orderDetails?.ship_phone ?? "NA")}
                {dataRow(t("Address"), orderDetails?.ship_address ?? "NA")}
                {dataRow(t("Post Code"), orderDetails?.ship_postcode ?? "NA")}
                {dataRow(t("City"), orderDetails?.ship_city ?? "NA")}
              </div>
              <div className="pb-s10"></div>
            </div>
          }
        />
      </div>

      <div className="mt-s22">
        <GroupAccordion
          accordion_type="dependent"
          customOnClick={() => setOpenedAccordion(3)}
          isInitOpen={openedAccordion === 3}
          header={
            <div className="flex justify-between items-center">
              <div className="font-fw600">{t("Sender Details")}</div>
            </div>
          }
          body={
            <div className="relative">
              <div
                onClick={async () => {
                  await navigator.clipboard.writeText(orderDetails?.bill_name + "\n" + orderDetails?.bill_phone + "\n" + orderDetails?.bill_postcode + "\n" + orderDetails?.bill_address + "\n" + orderDetails?.bill_city);
                  Toastr({ message: "Sender address copied to clipboard !", type: "success" });
                }}
                className="absolute bg-white rounded-full shadow-lg cursor-pointer -right-4 top-s10 p-s10"
              >
                <img src={CopyToClipboard} alt="" />
              </div>

              <div className="pr-8">
                {dataRow(t("Sender Name"), orderDetails?.bill_name ?? 'NA')}
                {dataRow(t("Phone"), orderDetails?.bill_phone ?? "NA")}
                {dataRow(t("Address"), orderDetails?.bill_address ?? "NA")}
                {dataRow(t("Post Code"), orderDetails?.bill_postcode ?? "NA")}
                {dataRow(t("City"), orderDetails?.bill_city ?? "NA")}
              </div>
              <div className="pb-s10"></div>
            </div>
          }
        />
      </div>
    </div>
  );

  function dataRow(dataName, dataDetails) {
    return (
      <div className="flex justify-between items-center capitalize pt-s10">
        <div className="font-medium">{dataName}</div>
        <div>{dataDetails}</div>
      </div>
    );
  }
};

const ShopDetails = ({ orderDetails }) => {
  const { t } = useTranslation();
  const { openedAccordion, setOpenedAccordion } = useOrderStore();

  return (
    <div className="pt-5">
      <GroupAccordion
        accordion_type="dependent"
        customOnClick={() => setOpenedAccordion(4)}
        isInitOpen={openedAccordion === 4}
        header={
          <div className="flex justify-between items-center">
            <div className="font-fw600">{t("Shop Details")}</div>
          </div>
        }
        body={
          <>
            {dataRow(t("Shop Name"), orderDetails?.post_card_with_shop?.shop?.name ?? "NA")}
            {dataRow(t("Phone"), orderDetails?.post_card_with_shop?.shop?.phone ?? "NA")}
            {dataRow(t("Address"), orderDetails?.post_card_with_shop?.shop?.address ?? "NA")}
            {dataRow(t("Post Code"), orderDetails?.post_card_with_shop?.shop?.post_code ?? "NA")}
            {dataRow(t("City"), orderDetails?.post_card_with_shop?.shop?.city ?? "NA")}
            <div className="pb-s10"></div>
          </>
        }
      />
    </div>
  );

  function dataRow(dataName, dataDetails) {
    return (
      <div className="flex justify-between items-center capitalize pt-s15">
        <div className="font-medium">{dataName}</div>
        <div>{dataDetails}</div>
      </div>
    );
  }
};

const SummeryOrder = ({ orderDetails }) => {
  const { t } = useTranslation();
  const { openedAccordion, setOpenedAccordion } = useOrderStore();

  let taxPercentage = orderDetails?.tax_percentage || 0

  let subTotalAmount = orderDetails?.price - orderDetails?.discount > 0 ? orderDetails?.price - orderDetails?.discount : 0
  let cardTaxAmount = orderDetails?.price_tax || 0
  let seviceTaxAmount = orderDetails?.authority_fee_tax || 0
  // if (orderDetails?.price > 0 && orderDetails?.price_tax > 0) vatPercentage = orderDetails?.price / orderDetails?.price_tax
  return (
    <div className="mt-5">

      <GroupAccordion
        accordion_type="dependent"
        customOnClick={() => setOpenedAccordion(5)}
        isInitOpen={openedAccordion === 5}
        header={
          <div className="flex justify-between items-center">
            <div className="font-fw600">{t("Summary")}</div>
          </div>
        }
        body={
          <>
            {dataRow(t("Card total price"), orderDetails?.price)}
            {dataRow(t("Applied Voucher"), orderDetails?.discount ?? 0)}
            <div className="my-s5"><hr></hr></div>
            {dataRow(t("Sub total"), subTotalAmount)}
            <div className="my-s5"><hr></hr></div>
            {dataRow(t("Sub total (" + taxPercentage + "% tax included)"), (subTotalAmount + cardTaxAmount))}
            {dataRow(t(`Service fee (${taxPercentage}% tax included)`), (orderDetails?.authority_fee + seviceTaxAmount) ?? 0)}
            {dataRow(t("Shipping fee & postage fee"), orderDetails?.shipping_fee ?? 0)}
            <div className="py-s10"></div>
          </>
        }
        footer={
          <>
            <div className="px-5">
              {dataRow(t(`Tax (${taxPercentage}%)`), cardTaxAmount + seviceTaxAmount)}
              {dataRow(t(`Total (${taxPercentage}% tax included)`), orderDetails?.order_total ?? 0)}
            </div>
            <div className="pb-s10"></div>
          </>
        }
      />
    </div>
  );

  function dataRow(dataName, dataDetails, showPriceIcon = true) {
    return (
      <div className="flex justify-between items-center capitalize pt-s10">
        <div className="font-medium">{dataName}</div>
        <div>{showPriceIcon ? "¥" : ""} {dataDetails}</div>
      </div>
    );
  }
};
const CancellationRequest = ({
  orderDetails,
  orderStatus,
  setShowOrderDetails,
  setOrderStatusTable,
  orderListPagination
}) => {
  const { setDropdownItem, openedAccordion, setOpenedAccordion } = useOrderStore();
  const [adminNote, setAdminNote] = useState("");

  return (
    <div className="mt-5">

      <GroupAccordion
        accordion_type="dependent"
        customOnClick={() => setOpenedAccordion(6)}
        isInitOpen={openedAccordion === 6}
        header={
          <div className="flex justify-between items-center">
            <div className="font-fw600">Cancel Request Details</div>
          </div>
        }
        body={
          <>
            <div className="flex items-center justify-between">
              <div className="text-base font-semibold pt-4 pb-3">
                Customer Cancellation Note
              </div>

              <div>{formatDate(orderDetails?.customer_note_date)}</div>
            </div>

            <div className="pb-s10">
              {orderDetails?.customer_note ?? "NA"}
            </div>
          </>
        }
        footer={
          orderDetails?.status === "cancellation_request" && <form onSubmit={(e) => e.preventDefault()}>
            <div className="px-5 pt-4">
              <CommonInput
                required
                textarea
                label={'Admin Confirmation'}
                placeholder={'Write reason here*'}
                value={adminNote}
                onChange={(e) => setAdminNote(e.target.value)}
              />
            </div>
            <div className="pb-s10 w-full flex justify-center">
              <CommonButton
                type="submit"
                btnLabel="Agree"
                onClick={async () => {
                  if (!adminNote) return Toastr({ message: "Please write admin confirmation note" }, { type: "error" });
                  let updateSuccess = await updateOrderAction(orderDetails?.id, 'canceled', orderListPagination.path + "?page=" + orderListPagination.current_page, true, adminNote);
                  // console.log("SUCCESS !!!", updateSuccess);
                  if (updateSuccess === true) {
                    setDropdownItem("all orders");
                    console.log("SELECTED! all orders");
                    setShowOrderDetails(false)
                    setOrderStatusTable(orderStatus);
                    if (window.location.pathname === "/gift-card-details") {
                      getCardOrderList("all orders", "", "");
                    }
                  }
                }}
              />
            </div>
          </form>
        }
      />
    </div>
  );
};
const CancelAdminConfirmation = ({ orderDetails }) => {
  const { t } = useTranslation();
  const { openedAccordion, setOpenedAccordion } = useOrderStore();

  return (
    <div className="mt-5">

      <GroupAccordion
        accordion_type="dependent"
        customOnClick={() => setOpenedAccordion(7)}
        isInitOpen={openedAccordion === 7}
        header={
          <div className="flex justify-between items-center">
            <div className="font-fw600">Admin's Confirmation</div>
          </div>
        }
        body={
          <>
            <div className="flex items-center justify-between">
              <div className="text-base font-semibold pt-4 pb-3">
                Admin's Confirmation Note
              </div>
              <div>{formatDate(orderDetails?.admin_note_date)}</div>
            </div>

            <div className="pb-s10">
              {orderDetails?.admin_note ?? "NA"}
            </div>
          </>
        }
      />
    </div>
  );
};